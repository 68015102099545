<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="resetForm()">
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Image</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="new-image-form">
          <v-text-field
            label="Name/Title"
            v-model="fields.name"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-file-input
            label="Image File"
            v-model="fields.image"
            :rules="rules"
            v-if="isEditing === false"
            outlined
            accept="image/png, image/jpeg"
            placeholder="Pick an image"
            prepend-icon="mdi-image"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>
          <v-select
            label="Categories"
            v-model="fields.categories"
            :items="categories"
            multiple
            item-text="name"
            item-value="id"
            outlined
            :error="errors.hasOwnProperty('categories')"
            :error-messages="errors['categories']"
          ></v-select>
          <v-switch
            label="Is for Drumgolf?"
            v-model="fields.is_drumgolf"
            inset
            color="green"
            :error="errors.hasOwnProperty('is_drumgolf')"
            :error-messages="errors['is_drumgolf']"
          ></v-switch>
          <v-switch
            label="Is for Drumscot?"
            v-model="fields.is_drumscot"
            inset
            color="green"
            :error="errors.hasOwnProperty('is_drumscot')"
            :error-messages="errors['is_drumscot']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false">Close</v-btn>
        <v-btn
          color="accent"
          depressed
          type="submit"
          :loading="loading"
          form="new-image-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: "",
        image: null,
        is_drumgolf: true,
        is_drumscot: false,
        categories: [],
      },
      errors: {},
      image: {},
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2MB!",
      ],
    };
  },

  computed: {
    categories() {
      return this.$store.state.drum.admin["imageCategories"];
    },
  },

  methods: {
    openForm: function (image = null) {
      if (image !== null) {
        this.image = image;
        this.isEditing = true;
        this.fields.name = image.name;
        this.fields.is_drumgolf = image.is_drumgolf == 1 ? true : false;
        this.fields.is_drumscot = image.is_drumscot == 1 ? true : false;
        this.fields.categories = image.categories.map((c) => {
          return c.id;
        });
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.fields.name = "";
      this.fields.image = null;
      this.fields.is_drumgolf = true;
      this.fields.is_drumscot = false;
      this.fields.categories = [];
      this.image = {};
      this.isEditing = false;
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        isEditing: this.isEditing,
      };

      let fields = this.fields;

      if (this.isEditing) {
        delete fields.image;
        payload.imageId = this.image.id;
      }

      payload.fields = fields;

      this.$store
        .dispatch("drum/admin/saveImage", { ...payload })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>