<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Images</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Images"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.imageForm.openForm()"
                >
                  <v-icon>mdi-image-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Image</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-drum-admin-images-categories' }"
                >
                  <v-icon>mdi-tag-multiple</v-icon>
                </v-btn>
              </template>
              <span>View Image Categories</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-drum-admin-images-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-for="image in images" v-bind:key="image.id">
          <v-card>
            <v-img
              :src="image.asset_urls.thumbnail"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
            >
              <v-card-title>{{ image.name }}</v-card-title>
              <v-card-subtitle class="white--text">{{
                image.categories
                  .map((c) => {
                    return c.name;
                  })
                  .join(", ")
              }}</v-card-subtitle>
            </v-img>
            <v-card-actions>
              <v-btn icon color="red" @click="openDelete(image)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn icon color="blue" @click="$refs.imageForm.openForm(image)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Image</v-card-title>
        <v-card-text>Are you sure you want to delete this image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ImageForm ref="imageForm" />
  </div>
</template>

<script>
import ImageForm from "./components/ImageForm";

export default {
  components: {
    ImageForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Admin",
          disabled: true,
        },
        {
          text: "Images",
          disabled: true,
        },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        image: {},
      },
    };
  },

  computed: {
    images() {
      let images = this.$store.state.drum.admin["images"];

      if (this.searchTerm !== "") {
        images = images.filter((i) => {
          const name = i.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return images;
    },
  },

  methods: {
    openDelete(image) {
      this.deleteDialog.image = image;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.open = false;
      this.deleteDialog.loading = false;
      this.deleteDialog.image = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("drum/admin/deleteImage", {
          appId,
          imageId: this.deleteDialog.image.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>